import React from "react";

import { LOGO } from "../../Config/constant";
import { useStyles } from "./style";

const Logo: React.FC = () => {
  const style = useStyles();
  return <img src={LOGO} alt="logo" className={style.img} />;
};

export default Logo;
